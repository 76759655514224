import request from '@/axios'
import type { LoginFormType, UserType, TokenType } from './types'

export const loginApi = (data: LoginFormType): Promise<IResponse<TokenType>> => {
  return request.post({ url: '/auth/login', data })
}

export const logoutApi = (): Promise<IResponse> => {
  return request.delete({ url: '/auth/logout' })
}

export const profileApi = (): Promise<IResponse<UserType>> => {
  return request.get({ url: '/auth/profile' })
}

export const updateProfileApi = (data: any): Promise<IResponse<string[]>> => {
  return request.patch({ url: '/auth/profile', data })
}

export const changePasswordApi = (data: any): Promise<IResponse<string[]>> => {
  return request.patch({ url: '/auth/password', data })
}
